import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import CallToAction from '../components/CTA'
import ProjectList from '../components/ProjectList'
import './index.scss'

const IndexPage = ({ data }) => {
  const {
    projects,
    intro_text,
    featured_project,
    featured_project_title,
    featured_project_title_color,
    featured_project_image,
  } = data.allPrismicHomepage.edges[0].node.data
  const featuredProject = {
    title: featured_project_title,
    uid: featured_project.uid,
    image: featured_project_image,
    color: featured_project_title_color,
  }
  return (
    <Layout>
      {featured_project && <FeaturedProject {...featuredProject} />}
      <div className="container">
        <div className="intro">
          <h1>{addLinkToIntro(intro_text)}</h1>
        </div>
        <ProjectList projects={projects} />
        <CallToAction />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query indexQuery {
    allPrismicHomepage {
      edges {
        node {
          data {
            intro_text
            featured_project_title
            featured_project_title_color
            featured_project_image {
              url
              thumbnails {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                lqip {
                  url
                }
              }
            }
            featured_project {
              uid
            }
            projects {
              project {
                uid
                document {
                  ... on PrismicWork {
                    id
                    data {
                      title {
                        text
                      }
                      index_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const FeaturedProject = ({ title, uid, image, color }) => {
  return (
    <Link to={`/projects/${uid}`} className="featured">
      <div className={`featured-text container ${!color ? 'light' : ''}`}>
        <div className="title">{title}</div>
        <div className="link">Explore this project</div>
      </div>
      <div>
        {image.url ? (
          <img
            alt=""
            src={image.thumbnails.small.url}
            srcSet={`
          ${image.thumbnails.small.url} 500w,
          ${image.thumbnails.medium.url} 1000w,
          ${image.thumbnails.large.url} 2000w,
          ${image.url} 4000w
        `}
            sizes="(orientation: portrait) calc((100vh - 16rem) * 1.666), 
          100vw"
          />
        ) : null}
      </div>
    </Link>
  )
}

const addLinkToIntro = introText =>
  introText.split('Studio WillemsPeeters').reduce((prev, curr) => [
    prev,
    <Link key="/about" to="/about">
      Studio WillemsPeeters
    </Link>,
    curr,
  ])
